import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { useI18N } from '@vivino/js-web-common';
import React from 'react';

import PartnerWineryBadge, {
  PartnerWineryBadgeSizes,
} from '@components/WineryPage/components/PartnerWineryBadge/PartnerWineryBadge';

import styles from './wineryStatusText.module.scss';

interface WineryStatusBadgeProps {
  isPartnerWinery: boolean;
}

const TRANSLATIONS_PATH = 'components.shared.winery_status_text';

const TRANSLATIONS: { [key: string]: string } = {
  verifiedWinery: `${TRANSLATIONS_PATH}.verified_winery`,
  winery_badge: `${TRANSLATIONS_PATH}.winery`,
};

function WineryStatusText({ isPartnerWinery }: WineryStatusBadgeProps) {
  const { t } = useI18N();

  if (isPartnerWinery) {
    return (
      <div className={styles.partnerWineryBlock}>
        <div className={styles.partnerBadge}>
          <PartnerWineryBadge size={PartnerWineryBadgeSizes.small} />
        </div>
        <Typography className={styles.partnerWineryText} type={TypographyType.BodyMedium}>
          {t(TRANSLATIONS.verifiedWinery)}
        </Typography>
      </div>
    );
  }

  return (
    <Typography className={styles.winery} type={TypographyType.LabelMedium}>
      {t(TRANSLATIONS.winery_badge)}
    </Typography>
  );
}

export default WineryStatusText;
